<template>
  <v-card class="pa-8">
    <h3>เพิ่มสินค้า</h3>
    <v-row>
      <v-col cols="6"
        ><span>เลือกหมวดหมู่ของสินค้า*</span>
        <v-select
          v-model="category"
          :items="itemsCategory"
          item-text="category"
          item-value="id"
          label="เลือกหมวดหมู่สินค้า"
          outlined
          dense
        ></v-select
      ></v-col>
      <v-col cols="6"
        ><span>เลือกประเภทของสินค้า*</span>
        <v-select
          v-model="type"
          :items="itemsType"
          item-text="type"
          item-value="id"
          label="เลือกประเภทสินค้า"
          outlined
          dense
        ></v-select
      ></v-col>

      <v-col cols="3"
        ><p class="pt-2">รหัสสินค้า*</p>
        <v-text-field
          v-model="sku"
          label="รหัสสินค้า"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="3"
        ><p class="pt-2">ชื่อสินค้า*</p>
        <v-text-field
          v-model="productName"
          label="ชื่อสินค้า"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6"
        ><p class="pt-2">รายละเอียด*</p>
        <v-text-field
          v-model="description"
          label="รายละเอียด"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="3"
        ><p class="pt-2">หน่วยสินค้า*</p>
        <v-text-field
          v-model="unit"
          label="หน่วย"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="3"
        ><p class="pt-2">ราคาปลีก*</p>
        <v-text-field
          v-model="price"
          label="ราคาปลีก"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="6"></v-col>
      <v-col cols="3"
        ><p class="pt-2">ราคาโหล</p>
        <v-text-field
          v-model="priceDozen"
          label="ราคาโหล"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="3"
        ><p class="pt-2">ราคาแพ็ค</p>
        <v-text-field
          v-model="pricePack"
          label="ราคาแพ็ค"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="3"
        ><p class="pt-2">แพ็คละ(ชิ้น)</p>
        <v-text-field
          v-model="unitPack"
          label="แพ็คละ"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12" sm="6" xs="12">
        <v-card outlined>
          <v-row no-gutters align="center">
            <v-col cols="11" class="mt-5 ml-10" id="step-3">
              <h2>รูปสินค้าหลัก*</h2>
              <v-img :src="showImage" width="200px"></v-img>
              <v-text-field
                v-model="picture_1"
                @click="changePic1()"
                :placeholder="imageName1"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure1"
                accept="image/*"
                @change="showPicture1($event)"
                style="display: none"
            /></v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" class="mt-5">
        <v-card outlined>
          <v-row no-gutters align="center">
            <v-col cols="11" class="mt-5 ml-10" id="step-3">
              <h2>รูปภาพสินค้าเพิ่มเติม</h2>
            </v-col>
            <v-col cols="12" class="mb-5"><v-divider></v-divider></v-col>
            <v-col cols="12" md="12" class="pl-3 mb-5" @click="onPickFile()">
              <v-row no-gutters align="center" justify="center">
                <v-file-input
                  v-model="DataImage"
                  :items="DataImage"
                  accept="image/jpeg, image/jpg, image/png"
                  @change="UploadImage()"
                  id="file_input"
                  multiple
                  :clearable="false"
                  style="display: none"
                ></v-file-input>
                <v-img
                  max-width="50"
                  src="@/assets/upload.png"
                  class="mr-3"
                ></v-img>
                <span>เลือกรูปภาพ</span>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="12"
              v-if="product_image.length !== 0"
              class="mb-5"
            >
              <draggable
                v-model="product_image"
                :move="onMove"
                @start="drag = true"
                @end="drag = false"
                class="pl-5 pr-5 row fill-height align-center sortable-list"
              >
                <v-col
                  v-for="(item, index) in product_image"
                  :key="index"
                  cols="3"
                  md="3"
                >
                  <v-card dense light class="pa-1">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-icon small light @click="RemoveImage(index, item)"
                        >mdi-close</v-icon
                      >
                    </v-card-actions>
                    <!-- <img width="100%"  :src="item.url" v-if="MethodProduct === 'Create'" />
                              <img width="100%" :src="`${PathImage}${item.url}`" v-else /> -->
                    <v-img
                      :src="item.productImgUrl"
                      aspect-ratio="1.8"
                      contain
                    ></v-img>
                    <v-card-text class="text-md-center">
                      <!-- <span class="subheading">{{item.name|truncate(20, '...') }}</span> -->
                    </v-card-text>
                  </v-card>
                </v-col>
              </draggable>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-row class="ma-5">
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="submit()">บันทึก</v-btn> -->
      </v-row>
    </v-row>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      id: "",
      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      sku: "",
      productName: "",
      description: "",
      unit: "",
      price: "",
      priceDozen: "",
      unitDozen: "",
      pricePack: "",
      unitPack: "",
      img: [],
      DataImage: [],
      product_image: [],
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
    };
  },
  async created() {
    var productdata = JSON.parse(
      Decode.decode(localStorage.getItem("productdata"))
    );
    console.log("productdata", productdata);
    this.id = productdata.id;
    this.getOne();
    // this.itemsCategory = cateDropdown.data.data;
    // this.itemsType = typeDropdown.data.data;
    // console.log(this.itemsCategory, this.itemsType);
  },
  methods: {
    async getOne() {
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      const typeDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/types`
      );
      this.itemsCategory = cateDropdown.data.data;
      this.itemsType = typeDropdown.data.data;
      console.log(this.itemsCategory, this.itemsType);
      ////////////get dropdown list/////////////
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/` + this.id
      );
      console.log("Product", response);
      const Product = response.data.data;
      this.category = Product.category;
      //   console.log('this.category',this.category);
      this.type = Product.type;
      this.sku = Product.sku;
      this.productName = Product.productName;
      this.description = Product.description;
      this.unit = Product.unit;
      this.price = Product.price;
      this.priceDozen = Product.priceDozen;
      this.unitDozen = Product.unitDozen;
      this.pricePack = Product.pricePack;
      this.unitPack = Product.unitPack;
      this.showImage = Product.imgUrl;
      this.product_image = Product.productImgs;
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImage(index, item) {
      console.log(index, item);
      if (item.id !== "-1") {
        this.Remove_img.push({
          id: item.id,
        });
      }
      this.product_image.splice(index, 1);
    },
    cancel() {
      this.$router.push("ManageProduct");
    },
  },
};
</script>